import { useState, useEffect } from "react";
import axios from "axios";
import { useBetween } from 'use-between';
import { getAuth, connectAuthEmulator } from "firebase/auth";

const useAuth = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState('');

  useEffect(() => {
    const auth = getAuth();
    if (window.location.hostname === 'localhost') {
      connectAuthEmulator(auth, "http://localhost:9099", { disableWarnings: true});
    }

    auth.onAuthStateChanged((isAuthenticated) => {
      if (isAuthenticated) {
        let userToken;
        auth.currentUser.getIdToken().then((idToken) => {
          userToken = idToken;
          setName(auth.currentUser.displayName);
          setAuthenticated(true);
          setLoading(false);
        }).catch(err => {
          console.log(err);
          setAuthenticated(false);
          setLoading(false);
        });
        auth.onIdTokenChanged(user => {
          if (user) {
            user.getIdToken().then(idToken => {
              userToken = idToken;
              setAuthenticated(true);
              setLoading(false);
            });
          } else {
            setAuthenticated(false);
            setLoading(false);
          }
        });
        axios.interceptors.request.use(config => {
          config.headers.Authorization = 'Bearer ' + userToken;
          return config;
        })
      } else {
        setAuthenticated(false);
        setLoading(false);
      }
    });
  }, []);

  return [authenticated, loading, name]
}

const useSharedAuth = () => useBetween(useAuth);

export default useSharedAuth;