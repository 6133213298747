import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDy1n9L1CjYLZyq1d8dq217pD53Bq1W7nI",
  authDomain: "game-with-friends-804e6.firebaseapp.com",
  projectId: "game-with-friends-804e6",
  storageBucket: "game-with-friends-804e6.appspot.com",
  messagingSenderId: "885769271010",
  appId: "1:885769271010:web:afe3da230c18b756e750bb",
  measurementId: "G-S57M45K1YE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;