import React, { useState, useEffect } from "react";
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import FormCheck from 'react-bootstrap/FormCheck'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import { getAuth, signInWithCustomToken } from "firebase/auth";

import "./Dashboard.css";
import steamSignInIcon from './assets/sse.png';
import useAuth from './auth';
import { useSearchParams } from "react-router-dom";

function Dashboard() {
  const [friends, setFriends] = useState([]);
  const [selectedFriends, setSelectedFriends] = useState([]);
  const [requestedFriendList, setRequestedFriendList] = useState();
  const [sharedGames, setSharedGames] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('token');
  
  const [auth, loading] = useAuth();

  useEffect(() => {
    if (token !== null) {
      setSearchParams(undefined);
      const auth = getAuth();
      signInWithCustomToken(auth, token).then((userCredential) => {
        
      })
    }
  }, [token, setSearchParams])

  useEffect(() => {
    if (!auth) return;

    axios.get('/api/friends').then(response => {
      if (response.data.data !== undefined) {
        setFriends(response.data.data);
      } else {
        console.log("empty response", response.data);
      }
    }).catch(err => {
      console.log('err ', err);
    });
  }, [auth]);

  function getSharedGames() {
    let params = new URLSearchParams();
    for (const friend of selectedFriends) {
      params.append('friend', friend);
    }
    const request = { params: params}
    axios.get('/api/sharedGames', request).then(response => {
      if (response.data.data !== undefined) {
        setSharedGames(response.data.data);
      } else {
        console.log("empty response", response.data);
      }
      setRequestedFriendList(selectedFriends)
    })
  }

  function handleCheckboxChange(event) {
    const steamId = event.target.id.split('-')[1];
    let newFriends = [];
    for (const friend of selectedFriends) {
      if (friend === steamId) continue;
      newFriends.push(friend);
    }
    if (event.target.checked) {
      newFriends.push(steamId);
    }
    setSelectedFriends(newFriends);
  }

  return (
    <>
    { auth ? (
    <Container>
      <Row>
        <Col className="page-content-wrapper" xs={8} lg={10}>
          <div className="page-content">
          {requestedFriendList !== undefined && sharedGames.length === 0 ? (
            <p>No shared games!</p>
          ) : (
            <>
              {requestedFriendList === undefined ? (
                <i className="darker padding-top">Select one or more friends to see shared games.</i>
              ) : <h4>Shared games</h4>}
              <ListGroup>
                {sharedGames.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)
                            .map((sharedGame) => {
                  return (
                    <a href={sharedGame.url} target="_blank" rel="noreferrer">
                      <ListGroup.Item key={sharedGame.appID} value={sharedGame.name} variant={sharedGame._count-1 >= requestedFriendList.length ? '' : 'secondary'} className="game-link">
                        <img src={sharedGame.iconURL} alt="Game logo"/>
                        {sharedGame.name}
                    </ListGroup.Item>
                    </a>
                  )
                })}
              </ListGroup>
            </>
          )}
          </div>
        </Col>
        <Col className="sidebar-wrapper" xs={2} lg={2}>
          <div className="sidebar">
          <h4>Friends</h4>
          <Form className="friends-list">
            <ListGroup>
              {friends.sort((a, b) => {
                if (a.personaState === b.personaState) {
                  return a.nickname.toLowerCase() < b.nickname.toLowerCase() ? -1 : 1;
                } else if (a.personaState === 1) {
                  return -1
                } else {
                  return 1
                }
              }).map((friend) => {
                return (
                  <ListGroup.Item key={friend.steamID} value={friend.nickname} variant={friend.personaState === 1 ? '' : 'secondary'}>
                    <FormCheck>
                      <FormCheck.Input type="checkbox" id={"check-"+friend.steamID} onChange={handleCheckboxChange}/>
                      <FormCheck.Label htmlFor={"check-"+friend.steamID}>
                          <img src={friend.avatar} alt="Avatar"/>
                        {friend.nickname}
                      </FormCheck.Label>
                    </FormCheck>
                  </ListGroup.Item>
                )
              })}
            </ListGroup>
          </Form>
          <Button className="friends-btn" hidden={friends.length === 0} disabled={selectedFriends.length === 0} variant="primary" onClick={getSharedGames}>Show shared games</Button>
          </div>
        </Col>
      </Row>
    </Container>
    ) : !auth && !loading ? 
      <>
        <div className="login-btn">
          <a href="/auth/steam">
            <img src={steamSignInIcon} alt="Log in with Steam"/>
          </a>
        </div>
      </>
    : <></> }
    </>
  );
}

export default Dashboard;