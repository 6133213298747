import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React from "react";

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

import Dashboard from "./Dashboard";
import firebase from './firebase';
import useAuth from "./auth";
import { getAnalytics } from "firebase/analytics";

function App() {
  getAnalytics(firebase);
  const [authenticated, , name] = useAuth();

  return (
    <>
      <Navbar bg="dark" variant="dark" style={{top: 0, zIndex: 400, position: "sticky"}}>
        <Container className="sticky-nav">
          <Navbar.Brand href="/">Game with friends</Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          { authenticated ?
              <Navbar.Text>
                {name}
              </Navbar.Text>
            : <></>
          }
        </Navbar.Collapse>
        </Container>
      </Navbar>

      <Router>
        <Routes>
          <Route exact path="/" element={<Dashboard/>} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
